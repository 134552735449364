@charset "utf-8";
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_media-queries.scss";
@import "/Users/huytran/Websites/vhost/morijuken2021/src/scss/utility/_mixin.scss";


.breadcrumb {
  @include SP {
    margin-top: 6px;
  }
}

.login {
  .login-intro {
    position: relative;
    text-align: center;
    background: url($img_url + 'img/login/member_bnr.jpg') no-repeat center;
    background-size: cover;
    padding: 58px 20px 69px;
    .txt {
      max-width: 880px;
      margin: 0 auto;
    }
    @include SP {
      background-image: url($img_url + 'img/login/member_bnr_sp.jpg');
      background-position: center top;
      padding: 41px 0 59px;
      .txt {
        max-width: 375px;
      }
    }
  }
  .login-block {
    padding-bottom: 120px;
    margin-top: 64px;
    @include SP {
      padding-bottom: 70px;
      margin-top: 40px;
    }
  }
  .login-box {
    margin-top: 70px;
    @include SP {
      margin-top: 60px;
    }
    #login-form {
      background: #EEF0F1;
      padding: 63px 30px 70px;
      @include SP {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
    .login-title {
      text-align: center;
      @include font(28,37,40);
      margin-bottom: 27px;
      @include SP {
        @include font(24,30);
      }
      .icon {
        margin-right: 6px;
        @include SP {
          margin-right: 4px;
        }
      }
    }
    .login-form {
      .login-form-group {
        max-width: 354px;
        margin: 0 auto;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        input {
          height: 50px;
          background: #fff;
          border: 1px solid #D7DBDD;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
          box-shadow: none;
          border-radius: 0;
          width: 100%;
          @include font(16);
          @include letter-spacing(40);
          padding: 0 40px 0 22px;
          @include SP {
            height: 45px;
            padding: 0 15px;
          }
          &::-webkit-input-placeholder {
            color: #8E8E8E;
            @include font(14);
          }
          &:-ms-input-placeholder {
            color: #8E8E8E;
            @include font(14);
          }
          &::placeholder {
            color: #8E8E8E;
            @include font(14);
          }
        }
        /* Change Autocomplete styles in Chrome*/
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
          transition: background-color 5000s ease-in-out 0s;
        }
        .icon-pass {
          cursor: pointer;
          position: absolute;
          width: 22px;
          height: 14px;
          right: 15px;
          top: 50%;
          z-index: 1;
          margin-top: -7px;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-image: url($img_url + 'img/common/icon/ico_show_pass.svg');
          &.is-active {
            background-image: url($img_url + 'img/common/icon/ico_hide_pass.svg');
          }
        }
      }
      .alert-warning {
        text-align: center;
        color: $red1;
        @include font(14,20);
        margin-bottom: 30px;
        & + .alert-warning {
          margin-top: -15px;
        }
      }
    }
    .login-remember {
      text-align: center;
      position: relative;
      margin-top: 14px;
      input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        visibility: hidden;
      }
      label {
        @include font(14,16,40);
        display: inline-block;
        position: relative;
        padding-left: 25px;
        @include SP {
          padding-left: 30px;
          line-height: 20px;
        }
        span.check {
          width: 16px;
          height: 16px;
          border: 1px solid #A8A8A8;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          @include SP {
            width: 20px;
            height: 20px;
          }
          &:after {
            border-left: 2px solid $red1;
            border-top: 2px solid $red1;
            content: '';
            display: none;
            height: 10px;
            left: 4px;
            position: absolute;
            top: 1px;
            -moz-transform: rotate(-135deg) skew(0deg, 0deg);
            -webkit-transform: rotate(-135deg) skew(0deg, 0deg);
            -ms-transform: rotate(-135deg) skew(0deg, 0deg);
            transform: rotate(-135deg) skew(0deg, 0deg);
            width: 6px;
            @include SP {
              left: 6px;
              top: 2px;
            }
          }
        }
      }
      input:checked + label {
        .check {
          &:after {
            display: block;
          }
        }
      }
    }
    button.cmn-button {
      max-width: 234px;
      margin: 35px auto 0;
      @include font(14,25,60,700);
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .register-btn {
    border: none;
    @include font(18,18,60,700);
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    padding: 15px 20px;
    height: 73px;
    max-width: 400px;
    margin: 82px auto 0;
    background: $red1;
    color: #fff;
    @include SP {
      height: 70px;
      width: calc(100% - 30px);
      margin-top: 50px;
      @include font(16,16);
      padding: 15px 10px;
    }
    @include ANDR {
      @include font-size(14);
    }
    .c-arr {
      display: inline-block;
      position: static;
      padding: 0;
      &:before {
        right: 20px;
        @include SP {
          right: 15px;
        }
      }
    }
    .icon {
      margin-right: 10px;
      width: 20px;
      height: 27px;
      background: url($img_url+'img/plan/ico_register.svg') 0 0/100% auto no-repeat;
      display: inline-block;
      @include IP5 {
        margin: 0 5px 0 -15px;
        width: 18px;
      }
    }
  }
  .cptch_block {
    margin-top: -12px;
    .cptch_reload_button{
      display: none;
    }
    .cptch_label,.cptch_wrap {
      display: block !important;
    }
  }
}